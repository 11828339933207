import { ROUTE } from "common/const";
import { useRouter } from "next/router";
import { useEffect } from "react";

const HomePage = () => {
  const router = useRouter();
  useEffect(() => {
    router.push(ROUTE.project);
  }, [router]);
  return <div></div>;
};

export default HomePage;
